import React, {useContext} from 'react';
import {renderRichText} from 'gatsby-source-contentful/rich-text';
import {GlobalContext} from 'hook/GlobalContext';
import htmlStringReactParser from 'html-react-parser';
import classNames from 'classnames';

import {removeNullFromProps} from 'utils/utilities';
import {getCloudinaryImageUrl} from 'utils/utilities';

import * as styles from './BannerMessage.module.scss';

const WHITE = '#FFFFFF';
const NAVY_BLUE = '#00325e';
const DEFAULT_CONTENT_TEXT = 'Livongo is now part of Teladoc Health.';

const BannerMessage = (props, ref) => {
    const {
        backgroundColorHex = NAVY_BLUE,
        content,
        ctaText,
        extendedContent,
        ctaLink,
        textColorHex = WHITE,
        icon,
    } = removeNullFromProps(props);
    const IconURL = getCloudinaryImageUrl(icon);

    const rootStyle = {
        backgroundColor: backgroundColorHex,
        color: textColorHex,
    };

    const {
        contactNumber,
        contactPhoneURL,
        physicianAttestationForm,
    } = useContext(GlobalContext);

    const options = {
        replace: ({attribs, children}) => {
            if (!attribs) {
                return;
            }

            switch (attribs.id) {
                case 'phoneNumber':
                    return <a href={contactPhoneURL}>{contactNumber}</a>;
                case 'physicianForm':
                    return (
                        <a href={physicianAttestationForm}>
                            {'Physician Attestation Form'}
                        </a>
                    );
                default:
                    break;
            }
        },
    };

    return (
        <div className={classNames(styles.root)} style={rootStyle}>
            <div className={styles.container}>
                <input type="checkbox" id="expandmsg" />
                {IconURL ? (
                    <img className={styles.peopleIcon} src={IconURL} alt="" />
                ) : (
                    ''
                )}

                <span className={styles.content}>
                    {content ? renderRichText(content) : DEFAULT_CONTENT_TEXT}{' '}
                </span>

                {ctaLink ? (
                    <a href={ctaLink} target="_blank">
                        {ctaText}
                    </a>
                ) : (
                    <label className={styles.expandLabel} for="expandmsg">
                        <div>{ctaText}</div>
                    </label>
                )}

                {extendedContent ? (
                    <div className={styles.message}>
                        {htmlStringReactParser(
                            extendedContent?.childMarkdownRemark?.html,
                            options
                        )}
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

export default BannerMessage;
